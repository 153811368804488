import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import makeRequest from '../../helpers/make-request'
import LeadershipView from './leadership-view'
import { useTranslation } from 'react-i18next'

const LeadershipController = () => {
  const [loading, setLoading] = useState(true)
  const [dataLeadership, setDataLeadership] = useState([])
  const controller = new AbortController()
  // catch with useEffect so the data will be contained
  const { i18n } = useTranslation();
  const getLeadershipData = async () => {
    setLoading(true)
    const fields = 'id,title,slug,acf.position,acf.linkedin,thumbnail,featured_media'
    const headers = {
      'Content-Type': 'application/json',
    }


    // fetch the data with makerequest
    makeRequest({
      headers,
      endPoint: 'leadership',
      signal: controller.signal,
      params: {
        fields,
        lang: i18n.language
      },
    }).then((resp) => {
      setDataLeadership(_.sortBy(resp.data, [(o) => o.acf.order]))
      setLoading(false)
    })
      .catch((error) => {
        console.error(error)
        setLoading(false)
      })
  }
  useEffect(() => {
    getLeadershipData()
    return () => {
      controller.abort()
    }
  }, [])

  const viewProps = {
    data: dataLeadership,
    loading,
  }
  return (
    <LeadershipView {...viewProps}/>
  )
}

export default LeadershipController
