import _ from "lodash"
import React from "react"
import { Trans } from "react-i18next"
import "./leadership-styles.scss"
import {
  Seo,
  Spinner,
  Tagline,
  CardTeam,
  GradientBanner,
} from "../../components"
import { useTranslation } from "react-i18next"
// import { t } from "i18next"

const LeadershipView = ({ data, loading }) => {
  if (loading) {
    return <Spinner loading={loading} />
  }

  const { t, i18n } = useTranslation();

  return (
    <div className="leadership__container">
      <Seo title="Leadership" />
      <div className="leadership__box">
        <div className="leadership__header">
          <Tagline text={t("footer-menu.leadership")} />
          <h1 className="leadership__title">
            <Trans i18nKey="leadership.title">
              Led by industry
              <span className="leadership__title-color">
                {{ experts: i18n.language !== 'en' ? '带領' : 'experts' }}
              </span>
            </Trans>
          </h1>
        </div>
        <div className="row leadership__list">
          {_.map(data, item => (
            <div key={item.id} className="col-6 col-md-4 col-xxl-4">
              <CardTeam data={item} />
            </div>
          ))}
        </div>
      </div>
      <GradientBanner />
    </div>
  )
}

export default LeadershipView
